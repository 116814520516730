import React from 'react';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import NewsPageModel from './Models/NewsPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { applyEditModeAttr } from '../../Features/Shared/Common/Helpers';
import Breadcrums from '../Shared/Breadcrumb/Breadcrumb';

function NewsPage() {
  const {
    heading,
    introText,
    inEditMode,
    bodyText,
    publishDate,
    tags,
  } = useCurrentPage<NewsPageModel>();
  const {
    translations: { 'newsPage/tags': tagsTranslation },
  } = useAppSettingsData();
  return (
    <Root>
      <ContentContainer>
        <Heading>{heading}</Heading>
        <DateAndTagsDiv>
          <DateSpan>{publishDate}</DateSpan>
          {!!tags.length && (
            <TagsWrapper>
              <Tags>{tagsTranslation + ':'}&nbsp;</Tags>
              {tags.map((tag, index) => (
                <p key={tag}>
                  {tag}
                  {index !== tags.length - 1 && ','}
                  &nbsp;
                </p>
              ))}
            </TagsWrapper>
          )}
        </DateAndTagsDiv>
        <Intro>{introText}</Intro>
        <BodyText
          {...applyEditModeAttr(inEditMode && 'BodyText')}
          dangerouslySetInnerHTML={{
            __html: bodyText,
          }}
        />
      </ContentContainer>
    </Root>
  );
}

const Root = styled.div({
  padding: { xy: theme.gamma },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { y: theme.massive },
  }),
});

const ContentContainer = styled.div({
  maxWidth: theme.maxWidthSmall,
  margin: { x: 'auto' },
});

const Heading = styled.h1({
  font: { size: theme.eta, weight: theme.fontWeight.lighter },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wider,
  marginBottom: theme.medium,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.iota,
    letterSpacing: theme.letterSpacing.header,
    marginBottom: theme.gamma,
  }),
});

const Intro = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.lighter },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  marginBottom: theme.huge,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.delta,
  }),
});

const DateAndTagsDiv = styled.div({
  display: 'flex',
  fontSize: theme.tau,
  letterSpacing: theme.letterSpacing.normalWide,
  justifyContent: 'space-between',
  marginBottom: theme.large,
  ...media(theme.mediaQuery.mediaMinSmall, {
    fontSize: theme.alpha,
    marginBottom: theme.gamma,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.beta,
  }),
});

const BodyText = styled.div({
  lineHeight: theme.lineHeight.relaxed,
  letterSpacing: theme.letterSpacing.normalWide,
});

const TagsWrapper = styled.div({
  display: 'flex',
});

const DateSpan = styled.p({
  opacity: 0.7,
});

const Tags = styled.p({
  fontWeight: theme.fontWeight.bold,
});

export default NewsPage;
